import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';

import { Provider } from 'react-redux';
import withRedux from 'next-redux-wrapper';
import withReduxSaga from 'next-redux-saga';
// import * as Sentry from '@sentry/react';

import styled from 'styled-components';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { BaseStyles, media } from '@pik/pik-ui';
import { auth, parseUserAgent } from '@pik/pik-utils';

import BannerPavlova40 from '../components/BannerPavlova40';

import Navigation from '../components/Navigation';
import ResponsiveContainer from '../components/ResponsiveContainer';
import MenuWrapper from '../components/MenuWrapper';
import Modal from '../components/Modal';
import Chat from '../components/Chat';
import SwitchObjects from '../components/SwitchObjects';
import HeadSeo from '../components/Head';

import { initApi } from '../common/api';
import apiServices from '../common/apiServices';
import handleRedirect from '../common/redirect';
import clientRendering from '../common/clientRendering';

import makeStore from '../services/store';
import { updateCustomer } from '../services/customer/actions';
import { getFooter } from '../services/footer/actions';
import { getMenu } from '../services/menu/actions';
import { getDeals } from '../services/deals/actions';
import { getComparison } from '../services/comparison/actions';
import { setUserAgent } from '../services/app/actions';
import '@pik/pik-client-mortgage/dist/index.css';
import '@pik/pik-client-purchase/dist/index.css';

const { getToken, checkAuth } = auth;

const routes = require('../server/routes');

dayjs.locale('ru');
dayjs.extend(customParseFormat);

// Sentry.init({
//   dsn: 'https://8e67a686d8384361b27a62a2f5bc4130@sentry.pikweb.net/6',
// });

export const MainWrapper = styled.div`
  background: #FAFAFA;
  min-height: calc(100vh + 160px);
  position: relative;
  padding-left:  ${({ hideNavigation }) => (hideNavigation ? '0' : '240px')};

  @media ${media.max1024} {
    padding-left: 64px;
  }

  @media ${media.max768} {
    padding-left: 0;
    padding-top: 48px;
    min-height: calc(100vh - 90px);
  }
`;

export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  margin: 0 auto;
  padding: 84px 26px;
  min-height: calc(100vh - 184px);

  @media ${media.max768} {
    padding: 58px 0;
    min-height: calc(100vh - 250px);
  }
`;

const getLinks = (node) => {
  const routesKeys = Object.keys(node);

  return routesKeys.reduce((acc, url) => {
    const { name, icon } = node[url];

    const link = {
      url,
      name,
      icon,
    };

    acc.push(link);

    return acc;
  }, []);
};

class PikClient extends App {
  static async getInitialProps({ Component, ctx }) {
    const { redirect } = ctx.query;
    let pageProps = {};
    let apiConfig = null;
    let customer = null;
    const pages = ctx.pathname.indexOf('pages') !== -1;

    if (ctx.res) {
      if (!pages) {
        customer = await checkAuth(ctx, apiServices, '/client');
      }

      const userAgent = parseUserAgent(ctx.req.headers['user-agent'] || '');
      ctx.store.dispatch(updateCustomer(customer));

      const token = getToken(ctx);

      apiConfig = {
        ...apiServices,
        token,
        userAgent: userAgent.source,
      };

      initApi(apiConfig);

      ctx.store.dispatch(setUserAgent(userAgent));
      ctx.store.dispatch(getMenu());
      ctx.store.dispatch(getFooter());

      if (token) {
        ctx.store.dispatch(getDeals(ctx));
        ctx.store.dispatch(getComparison());
      }
    }

    if (redirect) {
      handleRedirect(ctx, redirect);
    }

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return {
      pageProps,
      apiConfig,
      customer,
    };
  }

  // componentDidCatch(error, errorInfo) {
  //   Sentry.withScope((scope) => {
  //     Object.keys(errorInfo).forEach((key) => {
  //       scope.setExtra(key, errorInfo[key]);
  //     });
  //
  //     Sentry.captureException(error);
  //   });
  //
  //   super.componentDidCatch(error, errorInfo);
  // }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.apiConfig) {
      initApi(nextProps.apiConfig);
    }

    return null;
  }

  componentDidMount() {
    Router.onRouteChangeStart = () => window.scrollTo(0, 0);
  }

  checkPathName = (str) => {
    const { router: { pathname } } = this.props;

    return pathname.indexOf(str) !== -1;
  };

  render() {
    const {
      Component, store, pageProps, router,
    } = this.props;
    const navigationLinks = getLinks(routes['/client'].links);
    const showChatInModal = this.checkPathName('booking');
    const showSwitchObjects = this.checkPathName('property') || this.checkPathName('documents');
    const showStickers = this.checkPathName('property');
    const hideNavigation = this.checkPathName('pages');
    const isAuth = router && router.route && router.route === '/auth';
    const seoData = {
      title: 'Личный кабинет',
    };

    if (!clientRendering) {
      return (
        <>
          <Head />
          <HeadSeo data={seoData} />
          <Provider store={store}>
            <BaseStyles />
            <ResponsiveContainer />
          </Provider>
        </>
      );
    }

    if (isAuth) {
      return (
        <>
          <Head />
          <HeadSeo data={seoData} />
          <Provider store={store}>
            <BaseStyles />
            <Component {...pageProps} />
            <Modal />
            <ResponsiveContainer />
          </Provider>
        </>
      );
    }

    return (
      <>
        <Head />
        <HeadSeo data={seoData} />
        <Provider store={store}>
          <BaseStyles />
          <MenuWrapper />
          <MainWrapper id="AppWrapper" hideNavigation={hideNavigation}>
            {!hideNavigation && <Navigation links={navigationLinks} />}
            <BannerPavlova40 />
            <ComponentWrapper>
              <SwitchObjects showSwitchObjects={showSwitchObjects} showStickers={showStickers} />
              <Component {...pageProps} />
            </ComponentWrapper>
            <Modal />
            <div className="blackout" />
          </MainWrapper>
          <ResponsiveContainer />
          <Chat zIndex={showChatInModal} />
        </Provider>
      </>
    );
  }
}

export default withRedux(makeStore)(withReduxSaga(PikClient));
