import {
  takeEvery, call, put,
} from 'redux-saga/effects';

import {
  apiGetDeals,
} from './api';

import {
  actionTypes,
  getDealsSuccess,
  getDealsRequest,
  getDealsError,
} from './actions';

function checkPropertyType({ type_id: typeId }) {
  return {
    isFlat: typeId === 1 || typeId === 2,
    isCommercial: typeId === 4,
    isStorehouse: typeId === 6,
    isCars: typeId === 5,
  };
}
function createObjectData({
  id, hasStagesHidden, is_tax: isTax, name, settlement, flats, currentStage, isStageFinished,
  onlineSettlement, settlementStage,
}) {
  const objectsCount = flats.length;
  const object = flats[0];
  const { id: objectId } = object;
  const propertyTypes = checkPropertyType(object);

  const objectData = {
    deal: {
      id, hasStagesHidden, onlineSettlement, settlementStage, isTax, name, settlement,
    },
    objectsCount,
    flats,
    ...propertyTypes,
    ...object,
    currentStage,
    isStageFinished,
  };

  return {
    objectId,
    objectData,
  };
}

function getFormattedDeals(data) {
  const formatDeals = {};
  let currentId = null;

  if (Array.isArray(data)) {
    data.forEach((item) => {
      const isHaveFlat = item && item.flats && item.flats.length;
      if (isHaveFlat) {
        const { objectId, objectData } = createObjectData(item);
        formatDeals[objectId] = objectData;
        if (!currentId) {
          currentId = objectId;
        }
      }
    });
  }

  return { formatDeals, currentId };
}

function* getDealsSaga({ ctx }) {
  try {
    yield put(getDealsRequest());
    const deals = yield call(apiGetDeals);
    const { formatDeals, currentId } = getFormattedDeals(deals);

    yield put(getDealsSuccess(formatDeals, ctx, currentId));
  } catch (error) {
    console.error(error);
    yield put(getDealsError(error.data));
  }
}

export default function* watchSagas() {
  yield takeEvery(actionTypes.GET_DEALS, getDealsSaga);
}
